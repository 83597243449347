"use client"

import { WagmiConfig, configureChains, createConfig, useAccount } from "wagmi"
import { alchemyProvider } from "wagmi/providers/alchemy"
import { publicProvider } from "wagmi/providers/public"
import { jsonRpcProvider } from "wagmi/providers/jsonRpc"
import "@rainbow-me/rainbowkit/styles.css"
import { sliceChains } from "@slice-so/core"
import "@slice-so/react/dist/style.css"
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet"
import { WalletConnectConnector } from "wagmi/connectors/walletConnect"
import { MetaMaskConnector } from "wagmi/connectors/metaMask"
import { WalletKitConnector } from "react-walletkit"
import { Context, createContext, useContext, useEffect, useState } from "react"

// exemple url: http://localhost:3000?cart=1-3&cart=3-2

const infuraId = process.env.NEXT_PUBLIC_INFURA_ID || ""
const alchemyId = process.env.NEXT_PUBLIC_ALCHEMY_ID || ""
const projectId = process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID || ""
const walletkitProjectId = process.env.NEXT_PUBLIC_WALLETKIT_PROJECT_ID || ""
const chainId = process.env.NEXT_PUBLIC_CHAIN_ID || "8453"

const { chains, publicClient, webSocketPublicClient } = configureChains(
  sliceChains(chainId),
  [
    alchemyProvider({ apiKey: alchemyId }),
    jsonRpcProvider({
      rpc: () => ({
        http: `https://restless-side-vineyard.base-mainnet.quiknode.pro/43312ff3bb792b15d15dff7cda4aefe003329b54/`
      })
    }),
    jsonRpcProvider({
      rpc: () => ({
        http: `https://nd-418-081-331.p2pify.com/e2a12fe09f051376939ba43a60038cad`
      })
    }),
    // infuraProvider({ apiKey: infuraId }),
    publicProvider()
  ]
)

//TODO: promptForWalletKitWithEOA should be true in delivery
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "Bright Moments Venice"
      }
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId
      }
    }),
    new WalletKitConnector({
      chains,
      options: {
        projectId: walletkitProjectId,
        promptForWalletKitWithEOA: false
      }
    })
  ],
  publicClient,
  webSocketPublicClient
})

export const theme = {
  cartButtonColor: "!border-[#143767] !text-[#143767]",
  backgroundColor: "!bg-white",
  accentColor: "!text-[#E97B3F]",
  accentBackgroundColor: "!bg-[#E97B3F] disabled:!opacity-70 !text-white",
  mainCartButtonColor: "!bg-[#E97B3F] !text-white",
  textColor: ""
}

type ModalContextType = {
  isConnectModalOpen: boolean
  setIsConnectModalOpen: (open: boolean) => void
  isConnected: boolean
  setIsConnected: (connected: boolean) => void
}

const ModalContext: Context<ModalContextType> = createContext<ModalContextType>(
  {
    isConnectModalOpen: false,
    setIsConnectModalOpen: () => {},
    isConnected: false,
    setIsConnected: () => {}
  }
)

export function useModalContext() {
  return useContext(ModalContext)
}

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const { address } = useAccount()

  useEffect(() => {
    setIsConnected(!!address)
  }, [address])

  return (
    <ModalContext.Provider
      value={{
        isConnectModalOpen,
        setIsConnectModalOpen,
        isConnected,
        setIsConnected
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default function ClientLayout({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <WagmiConfig config={wagmiConfig}>
      <ModalProvider>{children}</ModalProvider>
    </WagmiConfig>
  )
}
